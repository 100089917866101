@font-face {
  font-family: "Satoshi";
  src: url("./fonts/Satoshi-Variable.ttf") format("truetype");
  /* font-weight: 400;
  font-style: normal; */
}
@font-face {
  font-family: "Plus Jakarta Sans";
  src: url("./fonts/PlusJakartaSans-VariableFont_wght.ttf") format("truetype");
  /* font-weight: 400;
  font-style: normal; */
}
@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
  /* font-weight: 400;
  font-style: normal; */
}
* {
  box-sizing: border-box;
}

body{
  padding-top:5rem;
}

body::-webkit-scrollbar {
  display: none;
}

:root {
  background-image: url("./assets/svg/background.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* You can also set other background properties here, like background-image, background-color, etc. */
}



